.container {
  height: 90vh;
  width: 100vw;
  font-family: "DarkerGrotesque-Regular";
  font-size: 3em;
  position: relative;
  display: block;
}

.container *::selection {
  background: transparent;
}

.content {
  position: relative;
  margin: 0 auto;
  width: 50%;
  padding: 10px;
  text-align: left;
}

.duration {
  visibility: hidden;
}

.title {
  visibility: hidden;
}

.description {
  visibility: hidden;
}
.animation {
  visibility: visible;
  animation: slideUp 1.5s ease forwards;
}
.animation1 {
  visibility: visible;
  animation: slideUp 1.5s ease forwards;
}
.animation2 {
  visibility: visible;
  animation: slideUp 2s ease forwards;
}
.animation3 {
  visibility: visible;
  animation: slideUp 2.5s ease forwards;
}

@keyframes reveal {
  0% {
    opacity: 0;
    width: 0px;
    overflow: hidden;
  }
  20% {
    width: 0px;
    overflow: hidden;
  }
  50% {
    transform: translateY(3rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateX(-13rem);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(4rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .content {
    width: 85%;
    transform: translate(0, 15%);
  }

  .title {
    font-size: 0.5em;
    margin-bottom: 10px;
  }

  .description {
    font-size: 0.4em;
  }

  .duration {
    font-size: 0.5em;
  }
}

@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .content {
    width: 85%;
    transform: translate(0, 10%);
  }

  .title {
    font-size: 1em;
  }

  .description {
    font-size: 0.5em;
  }

  .duration {
    font-size: 0.7em;
  }
}

@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .content {
    width: 85%;
    transform: translate(0, 10%);
  }

  .title {
    font-size: 1em;
  }

  .description {
    font-size: 0.5em;
  }

  .duration {
    font-size: 0.7em;
  }
}

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
  .content {
    width: 85%;
    transform: translate(0, 40%);
  }

  .title {
    font-size: 1em;
  }

  .description {
    font-size: 0.5em;
  }

  .duration {
    font-size: 0.7em;
  }
}

@media (min-width: 1281px) {
  /* big landscape tablets, laptops, and desktops */
  .content {
    width: 55%;
    transform: translate(0, 10%);
  }

  .title {
    font-size: 0.9em;
  }

  .description {
    font-size: 0.65em;
  }

  .duration {
    font-size: 0.8em;
  }
}
