.container {
  position: relative;
  top: 0;
  bottom: 0;
  margin: 0;
}

body {
  background-color: rgba(10, 25, 47, 0.99);
  color: #f0f0f0;
}

.loadingContainer {
  position: relative;
  margin: 0 auto;
  margin-left: -4%;
  margin-top:100px;
  text-align: center;
}

.loadingContent {
  position: relative;
  padding: 10px;
  top: 20vh;
  width: 200px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
}

.blurDiv {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  backdrop-filter: blur(5px);
  animation: blurEffect 2s linear infinite;
}

.loading {
  padding: 10px;
  position: relative;
  width: 100%;
  z-index: 0;
}

@keyframes blurEffect {
  0% {
    backdrop-filter: blur(0px);
  }
  50% {
    backdrop-filter: blur(4px);
  }
  100% {
    backdrop-filter: blur(0px);
  }
}

.hideSection {
  opacity: 0;
}
.img {
  height: 300px;
  z-index: 1;
  left: 0px;
  display: inline;
  position: relative;
  border-radius: 5px;
  transition: 0.5s ease;
}
.overlay {
  position: absolute;
  left: 0px;
  height: 300px;
  width: 300px;
  border-radius: 5px;
  z-index: 10;
  display: inline;
  background-color: rgba(183, 183, 183, 0.3);
  transition: 0.5s ease;
  animation: flipfront 0.3s ease-in-out forwards;
}

.overlayContainer:hover > .overlay {
  transition: 0.5s ease;
  animation: flipback 0.3s ease-in-out forwards;
}

@keyframes flipback {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(30px, -320px);
  }
  100% {
    transform: translate(20px, 20px);
    z-index: -1;
  }
}

@keyframes flipfront {
  0% {
    transform: translate(20px, 20px);
    z-index: -1;
  }
  50% {
    transform: translate(220px, 220px);
  }
  100% {
    transform: translateX(0rem);
  }
}

.overlayContainer {
  top: 100px;
  left: 150px;
  display: inline-block;
  position: relative;
  margin: 0 auto;
  text-align: center;
}

.appendStyling {
  visibility: visible;
  opacity: 1;
  animation: appear 2s ease forwards;
}

.contents {
  height: 100vh;
  min-height: 100vh;
  text-align: center;
  margin: 0 auto;
  font-size: 6em;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  flex-direction: column;
  align-items: flex-start;
  width: 60%;
}

.appearAnimation {
  animation: appear 2s ease forwards;
}

.aboutMe:hover {
  cursor: default;
}

.aboutMe span::selection,
.aboutMe p::selection {
  background: transparent;
}
.aboutMe {
  text-align: left;
  position: relative;
  font-family: "DarkerGrotesque-Regular";
  display: block;
  margin-top: -20%;
}

.aboutMe span:nth-child(1) {
  animation: opacity ease 1.75s;
}

.aboutMe span:nth-child(2) {
  animation: appear 1.75s ease backwards;
}

.aboutMe span p {
  animation: opacity ease 1.75s;
}

.fontSize120 {
  font-size: 120%;
}

.fontSize100 {
  font-size: 100%;
}

.fontSize80 {
  font-size: 80%;
}

.fontSize60 {
  font-size: 60%;
}

.fontSize30 {
  font-size: 30%;
}

.name {
  margin-top: -50px;
  margin-bottom: -110px;
}

.bio {
  margin-top: -60px;
}

.contentTitle {
  top: 100px;
  position: relative;
}

@keyframes opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateX(-13rem);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}