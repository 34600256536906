.navigationBar {
  position: fixed;
  width: 100%;
  display: block;
  transition: top 0.5s;
  background-color: rgba(10, 25, 47, 0.7);
  backdrop-filter: blur(6px);
  z-index: 100;
}
.navigationBar ul {
  list-style-type: none;
  float: right;
  padding: 10px;
  width: 100%;
}

.navigations {
  font-family: "DarkerGrotesque-Regular";
  font-size: 120%;
  float: right;
}

.navigations li {
  display: inline-block;
  margin-right: 70px;
  cursor: pointer;
  font-size: 120%;
  transition: 0.2s ease;
}

.navigations li::selection {
  background: transparent;
}

.logoContainer {
  height: 10px;
  width: 100px;
  margin-left: 100px;
}

.logo:hover {
  cursor: pointer;
}

.logo::selection {
  background: transparent;
}

.navigationBar ul li:hover {
  color: #98ffd5;
}

.appearAnimation {
  animation: appear 1s ease forwards;
}

.navigations li:nth-child(1) {
  animation: appear 1s ease forwards;
}

.navigations li:nth-child(2) {
  animation: appear 1.25s ease forwards;
}

.navigations li:nth-child(3) {
  animation: appear 1.5s ease forwards;
}

.navigations li:nth-child(4) {
  animation: appear 1.75s ease forwards;
}

.navigations li:nth-child(5) {
  animation: appear 2s ease forwards;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(-2rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .navigationBar {
    display: none;
  }
}

@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .navigationBar {
    display: none;
  }
}

@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .navigationBar {
    top:0px;
    display: block;
  }
}

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}

@media (min-width: 1281px) {
  /* big landscape tablets, laptops, and desktops */
}


.nav {
  position: fixed;
  top: -10px;
  width: 100%;
  font-weight: bold;
  background-color: rgba(223, 223, 223, 1);
  margin: 0 auto;
  text-align: center;
  transition: 0.3s;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  overflow-y: hidden;
  height: 0;
  opacity: 1;
  z-index: 900;
}

.nav div {
  position: relative;
  top: 30px;
  margin-top: 50px;
  color: black;
  font-size: 1.5em;
}

.bar1,
.bar2,
.bar3 {
  width: 40px;
  height: 5px;
  background-color: white;
  margin: 5px 0;
  transition: 0.3s;
  left: 10px;
  top: 10px;
  position: relative;
  z-index: 999;
}
.iconHolder {
  top: 10px;
  left: 10px;
  height: 50px;
  width: 60px;
  position: fixed;
  z-index: 900;
  transition: 0.5s ease all;
}

.icon {
  top: 0;
  display: block;
}

.iconHolder:hover {
  cursor: pointer;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 5px);
  background-color: black;
  z-index: 999;
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -7px);
  background-color: black;
  z-index: 999;
}

.mobileNavigationBar {
  display: none;
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .mobileNavigationBar {
    display: block;
  }
}

@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .mobileNavigationBar {
    display: block;
  }
}

@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .mobileNavigationBar {
    display: none;
  }
}
